var WeatherGenerator = (function (weather) {
  'use strict';

  var wg = function (weather) {
    this.weather = weather;
    this.days = parseInt(this.weather.dataset.days);
    this.temperature = this.weather.dataset.temperature;
    this.latitude = this.weather.dataset.latitude;
    this.longitude = this.weather.dataset.longitude;
    this.imgUrl = this.weather.dataset.img_url;
    this.city = this.weather.dataset.city;
    this.responsive = this.weather.dataset.responsive;
    this.getWeather = this.weather.dataset.endpoint;
    this.prevDomain = document.domain;
    this.currentDomain = this.getDomain();
    this.date = new Date();
  };

  wg.prototype.getDomain = function getDomain() {
    if (~this.prevDomain.indexOf('.local')) {
      return this.currentDomain = this.prevDomain.substring(0, this.prevDomain.length-6);
    }

    return this.prevDomain;
  };

  wg.prototype.upDay = function upDay(day) {
    day++;
    return ((day == 7) ? 0 : day);
  };

  wg.prototype.formatTime = function formatTime(num, temperature) {
    if (temperature == 'C') {
      var newNum = (parseInt(num) - 32) * 5 / 9;
      return Math.round(newNum);
    }

    if (temperature == 'F') {
      var newNum = (parseInt(num) * 5 / 9) + 32;
      return Math.round(newNum);
    }
  };

  wg.prototype.searchWeatherData = function searchWeatherData() {
    $.ajax({
      type:  'POST',
      data: {weather_domain: this.currentDomain, latitud: this.latitude, longitud: this.longitude, city: this.city},
      url: this.getWeather,
      dataType: 'json',
      success: $.proxy(function(data) {
        this.paintWeatherData(JSON.parse(data));
      }, this)
    })
    .error(function(jqXHR, textStatus, errorThrown) {
      // alert("error: " + JSON.stringify(jqXHR));
    })
  };

  wg.prototype.paintWeatherData = function paintWeatherData(weatherData) {
    weatherData = weatherData["dataWeather"];
    var weatherContent = document.createElement('ul');
    var currentDay = this.date.getDay();
    var dayOfMonth = this.date.getDate();
    var lang = document.documentElement.lang;

    function getLangDaysInWeek(lang){
      var daysInWeek = {
        'BR' : ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
        'CA' : ['Diumenge', 'Dilluns', 'Dimarts', 'Dimecres', 'Dijous', 'Divendres', 'Dissabte'],
        'DA' : ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
        'DE' : ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        'DU' : ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
        'EN' : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        'ES' : ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'],
        'FR' : ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        'IT' : ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        'JA' : ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        'PO' : ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        'PT' : ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
        'RU' : ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
        'TR' : ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        'US' : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        'ZH' : ['周日', '周一', '周二', '周三', '周四', '周五	', '周六'],
        'KO' : ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
      };
  
      return daysInWeek[lang] || daysInWeek['EN'];
    }

    weatherContent.className = 'weather-content';
    weatherContent.className += ' ' + this.responsive;
    this.weather.appendChild(weatherContent);
    var weatherDay;
    var temperatureContent;
    var objIcon;
    var day;
    var currentlyRow;
    var cityData;
    var cityWrap;
    var dayName;
    var currentlyOverlay;

    for (var i = 0; i < this.days; i++) {
      weatherDay = document.createElement('li');
      weatherDay.className = 'weather-day';
      temperatureContent = document.createElement('span');
      objIcon = document.createElement('i');
      day = document.createElement('span');
      dayName = getLangDaysInWeek(lang)[currentDay];
      day.className = 'day';
      temperatureContent.className = 'temperature';
      currentlyRow = document.createElement('div');
      currentlyRow.className = 'currently-row';
      cityData = document.createElement('div');
      cityData.className = 'location-data';
      cityWrap = document.createElement('span');
      cityWrap.className = 'location';

      if (i == 0) {
        weatherDay.className += ' currently fade-in';
        temperatureContent.innerHTML = this.formatTime(Math.round(weatherData['currently']['temperature']), this.temperature) + '°';
        objIcon.className = 'icon icon-' + weatherData.currently.icon;
        day.innerHTML = dayName + ' ' + dayOfMonth;
        cityWrap.innerHTML = '<span class="glyphicon glyphicon-map-marker"></span> ' + this.city;
        weatherDay.setAttribute('data-src', '/child/assets/img/tiempo.jpg');
        currentlyOverlay = document.createElement('div');
        cityData.appendChild(temperatureContent);
        cityData.appendChild(cityWrap);
        currentlyRow.appendChild(objIcon);
        currentlyRow.appendChild(cityData);
        weatherDay.appendChild(currentlyRow);
        weatherDay.appendChild(day);
        weatherDay.appendChild(currentlyOverlay);
      }

      if (i != 0) {
        day.innerHTML = dayName.substring(0, 10);
        temperatureContent.innerHTML = this.formatTime(Math.round(weatherData.daily.data[i-1].temperatureMax), this.temperature) + '°';
        objIcon.className = 'icon icon-' + weatherData.daily.data[i-1].icon;
        weatherDay.appendChild(day);
        weatherDay.appendChild(objIcon);
        weatherDay.appendChild(temperatureContent);
      }

      weatherContent.appendChild(weatherDay);
      currentDay = this.upDay(currentDay);
    }
  };

  wg.prototype.initialize = function initialize() {
    this.searchWeatherData();
  };

  return wg;
}());

var weathers = document.querySelectorAll('[data-function~="weather"]');

for(var i = 0; i < weathers.length; i++) {
  new WeatherGenerator(weathers[i]).initialize();
}
